@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(48, 48, 48, 0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		transform: translatey(0px);
	}
}

.avatar {
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height: 600px;
  width: 600px;
  margin-top: 100px;
  background-color: white;
	border-radius: 50%;
  overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}