@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#scroller {
  /* fill parent */
  display: block;

  /* set to some transparent color */
  border-color: rgba(0, 0, 0, 0.0);
  /* here we make the color transition */
  transition: border-color 0.75s linear;
  /* make this element do the scrolling */
  overflow: auto;

  background-color: white;
}

#scroller:hover {
  /* the color we want the scrollbar on hover */
  border-color: rgba(12, 13, 119, 0.3);
}

#scroller::-webkit-scrollbar,
#scroller::-webkit-scrollbar-thumb,
#scroller::-webkit-scrollbar-corner {
  /* add border to act as background-color */
  width: 6px;
  border-right-style: inset;
  /* sum viewport dimensions to guarantee border will fill scrollbar */
  border-right-width: calc(100vw + 100vh);
  /* inherit border-color to inherit transitions */
  border-color: inherit;
}
@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(48, 48, 48, 0.2);
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(48, 48, 48, 0.2);
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

.avatar {
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height: 600px;
  width: 600px;
  margin-top: 100px;
  background-color: white;
	border-radius: 50%;
  overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(48, 48, 48, 0.6);
	-webkit-transform: translatey(0px);
	        transform: translatey(0px);
	-webkit-animation: float 6s ease-in-out infinite;
	        animation: float 6s ease-in-out infinite;
}

.chess-container {
  display: flex;
  width: 72px;
  height: 72px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  flex-direction: column;
}

.chess-img {
  max-height: 50px;
}

.chess-status-bar {
  width: 32px;
  height: 4px;
  margin: 0 auto 4px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #203661;
}

.chess-hp {
  height: 100%;
  border-radius: 3px;
  transition: all 1s;
}

.shake{
  -webkit-animation: shake 1s;
          animation: shake 1s;
}
@-webkit-keyframes shake {
0%  { -webkit-transform: translate(2px, 1px)   rotate(0deg); transform: translate(2px, 1px)   rotate(0deg);  }
10% { -webkit-transform: translate(-1px, -2px) rotate(-2deg); transform: translate(-1px, -2px) rotate(-2deg); }
20% { -webkit-transform: translate(-3px, 0px)  rotate(3deg); transform: translate(-3px, 0px)  rotate(3deg);  }
30% { -webkit-transform: translate(0px, 2px)   rotate(0deg); transform: translate(0px, 2px)   rotate(0deg);  }
40% { -webkit-transform: translate(1px, -1px)  rotate(1deg); transform: translate(1px, -1px)  rotate(1deg);  }
50% { -webkit-transform: translate(-1px, 2px)  rotate(-1deg); transform: translate(-1px, 2px)  rotate(-1deg); }
60% { -webkit-transform: translate(-3px, 1px)  rotate(0deg); transform: translate(-3px, 1px)  rotate(0deg);  }
70% { -webkit-transform: translate(2px, 1px)   rotate(-2deg); transform: translate(2px, 1px)   rotate(-2deg); }
80% { -webkit-transform: translate(-1px, -1px) rotate(4deg); transform: translate(-1px, -1px) rotate(4deg);  }
90% { -webkit-transform: translate(2px, 2px)   rotate(0deg); transform: translate(2px, 2px)   rotate(0deg);  }
100%{ -webkit-transform: translate(1px, -2px)  rotate(-1deg); transform: translate(1px, -2px)  rotate(-1deg); }
}
@keyframes shake {
0%  { -webkit-transform: translate(2px, 1px)   rotate(0deg); transform: translate(2px, 1px)   rotate(0deg);  }
10% { -webkit-transform: translate(-1px, -2px) rotate(-2deg); transform: translate(-1px, -2px) rotate(-2deg); }
20% { -webkit-transform: translate(-3px, 0px)  rotate(3deg); transform: translate(-3px, 0px)  rotate(3deg);  }
30% { -webkit-transform: translate(0px, 2px)   rotate(0deg); transform: translate(0px, 2px)   rotate(0deg);  }
40% { -webkit-transform: translate(1px, -1px)  rotate(1deg); transform: translate(1px, -1px)  rotate(1deg);  }
50% { -webkit-transform: translate(-1px, 2px)  rotate(-1deg); transform: translate(-1px, 2px)  rotate(-1deg); }
60% { -webkit-transform: translate(-3px, 1px)  rotate(0deg); transform: translate(-3px, 1px)  rotate(0deg);  }
70% { -webkit-transform: translate(2px, 1px)   rotate(-2deg); transform: translate(2px, 1px)   rotate(-2deg); }
80% { -webkit-transform: translate(-1px, -1px) rotate(4deg); transform: translate(-1px, -1px) rotate(4deg);  }
90% { -webkit-transform: translate(2px, 2px)   rotate(0deg); transform: translate(2px, 2px)   rotate(0deg);  }
100%{ -webkit-transform: translate(1px, -2px)  rotate(-1deg); transform: translate(1px, -2px)  rotate(-1deg); }
}

.cardBox {
    transition: all 0.25s;
}

.cardBox:hover {
    cursor: pointer;
    position: relative;
    -webkit-transform: translate(-10px,-10px);
            transform: translate(-10px,-10px);
    box-shadow: 20px 20px 4px rgba(0, 0, 0, 0.25);
}
