.chess-container {
  display: flex;
  width: 72px;
  height: 72px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  flex-direction: column;
}

.chess-img {
  max-height: 50px;
}

.chess-status-bar {
  width: 32px;
  height: 4px;
  margin: 0 auto 4px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #203661;
}

.chess-hp {
  height: 100%;
  border-radius: 3px;
  transition: all 1s;
}

.shake{
  animation: shake 1s;
}
@keyframes shake {
0%  { transform: translate(2px, 1px)   rotate(0deg);  }
10% { transform: translate(-1px, -2px) rotate(-2deg); }
20% { transform: translate(-3px, 0px)  rotate(3deg);  }
30% { transform: translate(0px, 2px)   rotate(0deg);  }
40% { transform: translate(1px, -1px)  rotate(1deg);  }
50% { transform: translate(-1px, 2px)  rotate(-1deg); }
60% { transform: translate(-3px, 1px)  rotate(0deg);  }
70% { transform: translate(2px, 1px)   rotate(-2deg); }
80% { transform: translate(-1px, -1px) rotate(4deg);  }
90% { transform: translate(2px, 2px)   rotate(0deg);  }
100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}
